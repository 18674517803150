import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "./services/aws-config";
import { Analytics } from "./services/AnalyticsService";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(vuetify);

app.use(Analytics, {
  endpoint: "/api/analytics/events/new",
  batchSize: 10,
  flushInterval: 5000,
});

app.mount("#app");
