/** analytics.js
This code is a simple analytics service that tracks events and sends them to a server in batches.
It provides a Vue plugin that can be installed in a Vue app to make the analytics service available globally.
The service tracks events like page views, button clicks, form submissions, and errors.
It also automatically tracks route changes and errors in the app.
The service sends events to a specified endpoint in batches, with a configurable batch size and flush interval.
The service uses the Fetch API to send events to the server.

It was authored by Claude 3.5 Sonnet
 */

export class AnalyticsService {
  constructor(options = {}) {
    this.endpoint = options.endpoint || "/api/analytics";
    this.batchSize = options.batchSize || 10;
    this.flushInterval = options.flushInterval || 5000;
    this.events = [];
    this.startBatchProcessing();
  }

  startBatchProcessing() {
    setInterval(() => this.flush(), this.flushInterval);
  }

  async flush() {
    if (this.events.length === 0) return;

    const eventsToSend = [...this.events];
    this.events = [];

    try {
      console.log("Sending events:", eventsToSend);
      await fetch(this.endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(eventsToSend),
      });
    } catch (error) {
      console.error("Analytics flush failed:", error);
      // Requeue failed events
      this.events.push(...eventsToSend);
    }
  }

  track(eventName, data = {}) {
    const event = {
      event: eventName,
      timestamp: Date.now(),
      ...data,
      url: window.location.href,
    };

    this.events.push(event);
    if (this.events.length >= this.batchSize) {
      this.flush();
    }
  }
}

export const Analytics = {
  install(app, options = {}) {
    const analytics = new AnalyticsService(options);

    // Make available via this.$track
    app.config.globalProperties.$track = analytics.track.bind(analytics);

    // Make available via inject('analytics')
    app.provide("analytics", analytics.track.bind(analytics));

    // Auto-track route changes
    if (app.config.globalProperties.$router) {
      app.config.globalProperties.$router.afterEach((to) => {
        analytics.track("pageView", {
          path: to.path,
          name: to.name,
        });
      });
    }

    // Auto-track errors
    app.config.errorHandler = (error, instance, info) => {
      analytics.track("error", {
        error: error.message,
        component: instance?.$options.name,
        info,
      });
    };
  },
};
